.cover {
	position: relative;
	height: 100vh;
	min-height: 300px;
	
	.siteborder & {
		height: calc(100vh - 40px);
	
		&:after {
			content: '';
			position: absolute;
			left: -$page_siteborder;
			right: -$page_siteborder;
			top: -$page_siteborder;
			bottom: -$page_siteborder;
			z-index: -1;
		}
	}
		
	&--full {
		height: 100vh;
		
		.siteborder & {
			height: calc(100vh - 40px);
		}
	}

	&--half {
		height: 50vh;
		
		.siteborder & {
			height: calc(50vh - 40px);
		}
	}

	&--third {
		height: 33vh;
		
		.siteborder & {
			height: calc(33vh - 40px);
		}
	}
	
	&--fullscreen &__image {
		width: 100%;
		margin: 0;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}
	
	&--halfright &__image {
		width: 100%;
		left: 0;
		right: 0;

		@include breakpoint(medium) {
			position: absolute;
			width: 50%;
			right: 0;
			left: auto;
		}

	}

	&--halfleft &__image {
		width: 100%;
		left: 0;
		right: 0;

		@include breakpoint(medium) {
			width: 50%;
			left: 0;
		}

	}
		


	
	&__text {
		z-index: 10;	
		position: absolute;
	}
	
	&__image {
		z-index: 1;
		position: absolute;
		height: 100%;
		overflow: hidden;

		img {
			object-fit: cover;	
			width: 100%;
			height: 100%;

			@keyframes zoomy {
				0% {
					transform: scale(1);
				}
				100% {
					transform: scale(1.1);
				}
			}

			animation: zoomy 35s ease 2s forwards;
		}
	}
	
	&__overlay {
		position: absolute;
		width: 100%;
		height: 100%;
		margin: 0;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 2;
		
		&--light {
			background: rgba(255,255,255,.3);
		}
		
		&--dark {
			background: rgba(0,0,0,.3);
		}

		&--gradientlight {
			background: linear-gradient(to bottom, rgba(255,255,255,0) 75%, rgba(255,255,255,.4) 90%);
		}

		&--gradientdark {
			background: linear-gradient(to bottom, rgba(0,0,0,0) 75%, rgba(0,0,0,.4) 90%);
		}
	}
	
	&__meta {
		margin-top: 1.5rem;
	}
	
	&__location {
		z-index: 3;
	}
	
}

.cover--halfright.cover--text-centered {
	
	.cover__text {
		text-align: left;
		width: 100%;
		padding: 2rem;
		bottom: 0;

		@include breakpoint(medium) {
			padding: 0;
			bottom: auto;
			text-align: right;
			top: 45%;
			width: calc(50% - 4rem);
			max-width: 700px;
			right: calc(50% + 2rem);
			transform: translateY(-50%);
		}

	}

	
	.cover__location {
		right: calc(50% + 2rem);
		bottom: 2rem;
		
		.siteborder & {
			bottom: 0;
		}
	}
}

.cover--halfright.cover--text-top {
	
	.cover__text {
		text-align: left;
		width: 100%;
		padding: 2rem;
		bottom: 0;

		@include breakpoint(medium) {
			padding: 0;
			bottom: auto;
			text-align: right;
			top: 2rem;
			right: calc(50% + 2rem);
			width: calc(50% - 4rem);
			max-width: 700px;
		}

	}

	
	.cover__location {
		right: calc(50% + 2rem);
		bottom: 2rem;
		
		.siteborder & {
			bottom: 0;
		}
	}
}

.cover--halfright.cover--text-bottom {
	
	.cover__text {
		text-align: left;
		width: 100%;
		padding: 2rem;
		bottom: 0;

		@include breakpoint(medium) {
			padding: 0;
			bottom: auto;
			text-align: right;
			bottom: 2rem;
			right: calc(50% + 2rem);
			width: calc(50% - 4rem);
			max-width: 700px;
		}

		.siteborder & {
			bottom: 0;
		}

	}

	
	.cover__location {
		right: calc(50% + 2rem);
		top: 2rem;
		
		.siteborder & {
			top: 0;
		}
	}
}


.cover--halfleft.cover--text-centered {
	
	.cover__text {
		text-align: left;
		width: 100%;
		padding: 2rem;
		bottom: 0;

		@include breakpoint(medium) {
			padding: 0;
			bottom: auto;
			width: calc(50% - 4rem);
			top: 45%;
			left: calc(50% + 2rem);
			transform: translateY(-50%);
			max-width: 700px;
		}

	}
	
	.cover__location {
		left: calc(50% + 2rem);
		bottom: 2rem;
		
		.siteborder & {
			bottom: 0;
		}
	}
}

.cover--halfleft.cover--text-top {
	
	.cover__text {
		text-align: left;
		width: 100%;
		padding: 2rem;
		bottom: 0;

		@include breakpoint(medium) {
			padding: 0;
			bottom: auto;
			text-align: left;
			top: 2rem;
			left: calc(50% + 2rem);
			width: calc(50% - 4rem);
			max-width: 700px;
		}

	}

	
	.cover__location {
		left: calc(50% + 2rem);
		bottom: 2rem;
		
		.siteborder & {
			bottom: 0;
		}
	}
}

.cover--halfleft.cover--text-bottom {
	
	.cover__text {
		text-align: left;
		width: 100%;
		padding: 2rem;
		bottom: 0;

		@include breakpoint(medium) {
			padding: 0;
			bottom: auto;
			text-align: left;
			bottom: 2rem;
			left: calc(50% + 2rem);
			width: calc(50% - 4rem);
			max-width: 700px;
		}

		.siteborder & {
			bottom: 0;
		}

	}

	
	.cover__location {
		left: calc(50% + 2rem);
		top: 2rem;
		
		.siteborder & {
			top: 0;
		}
	}
}


.cover--fullscreen.cover--text-centered {
	
	.cover__text {
		text-align: left;
		width: 100%;
		padding: 2rem;
		bottom: 0;

		@include breakpoint(medium) {
			padding: 0;
			bottom: auto;
			text-align: center;
			top: 45%;
			width: 95%;
			max-width: 700px;
			margin-left: 50%;
			transform: translateX(-50%) translateY(-50%);
		}

	}
	
	.cover__location {
		left: 2rem;
		bottom: 2rem;
	}
}

.cover--fullscreen.cover--text-top {
	
	.cover__text {
		text-align: left;
		width: 100%;
		padding: 2rem;
		bottom: 0;

		@include breakpoint(medium) {
			padding: 0;
			bottom: auto;
			top: 6rem;
			left: 2rem;
			width: 95%;
			max-width: 700px;
		}

	}
	
	.cover__location {
		left: 2rem;
		bottom: 2rem;
	}
}

.cover--fullscreen.cover--text-bottom {
	
	.cover__text {
		text-align: left;
		width: 100%;
		padding: 2rem;
		bottom: 0;

		@include breakpoint(medium) {
			padding: 0;
			bottom: 2rem;
			left: 2rem;
			width: calc(95% - 2rem);
			max-width: 700px;
		}

		.siteborder & {
			bottom: 2rem;
		}
	}

	
	
	.cover__location {
		right: 2rem;
		bottom: 2rem;
	}
}

