.singlearticle {
  display: flex;
  width: calc(100% - 20px);
  margin-left: 10px;
  position: relative;
  margin-bottom: 5rem;
  justify-content: center;
  flex-wrap: wrap;

  @include breakpoint(medium) {
    flex-wrap: nowrap;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

			animation: zoomy 35s ease 2s forwards;
  }

  &__image {
    order: 1;
    margin-bottom: 2rem;
    position: relative;
    overflow: hidden;

    @include breakpoint(medium) {
      margin-bottom: 0;
    }

  }

  &__image--landscape {
    width: 100%;
    height: 40rem;

    @include breakpoint(medium) {
      width: calc(50% - 1rem);
    }
  }

  &__image--portrait {
    width: 100%;
    height: 40rem;

    @include breakpoint(medium) {
      width: calc(50% - 1rem);
      height: 42rem;
    }

    @include breakpoint(large) {
      width: calc(35% - 1rem);
      min-width: 40rem;
      margin-right: 1rem;
      height: 48rem;
    }
  }

  &__text {
    padding-right: 2rem;
    order: 2;
    margin-left: 2rem;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    padding-bottom: 14%;
    width: 100%;

    @include breakpoint(medium) {
      width: calc(50% - 1rem);
      padding-bottom: 0;
    }

    > * {
      width: 100%;
    }
    
  }

  &__intro {
    max-width: 40em;
    margin-bottom: 1rem;
  }

  &__meta {
    margin-bottom: .5rem;
  }

  &__subline {
    font-size: .8rem;
    margin-bottom: 2rem;
    text-transform: uppercase;
    font-weight: 600;
    color: grey;
  }
  
  &__date {
    display: none;
  }

  &__tags, 
  &__location {
    font-size: 1rem;
    font-weight: 600;
  }

  &__read-more {
    margin-top: 1rem;
  }
}

			@keyframes zoomy {
				0% {
					transform: scale(1);
				}
				100% {
					transform: scale(1.1);
				}
			}
