.form {
    max-width: 40rem;
    margin: auto;

    .hp {
        position: absolute;
        width: 1px;
        height: 1px;
        visibility: hidden;
    }

    &__element {
        display: flex;
        justify-content: space-between;

        label {
            width: 15rem;
            text-align: right;
            padding-right: 2rem;
            margin-bottom: 1rem;
        }

        &--text {
            input, 
            textarea {
                width: 100%;
                background: white;
                border: 1px solid #616161;
                padding: .4rem;
                font-size: 1.1rem;
                margin-bottom: 1rem;
            }
        }

        &--submit {
            justify-content: center;
            
            button,
            input {
                background: white;
                border-radius: 3px;
                border: 1px solid #616161;
                padding: .4rem 2rem;
                font-size: 1.1rem;    
                font-weight: bold;
                
                &:active {
                    background: #616161;
                    color: white;
                }
            }
        }
    }

    &__result {
        margin-bottom: 1rem;
    }


    &__description {
        margin-bottom: 1rem;
    }



}