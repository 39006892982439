.shop {
    text-align: center;
    
    &__item {
        margin-bottom: 2rem;
    }

    &__image {
        height: 18rem;
        display: flex;
        align-content: center;
        justify-content: center;

        img {
            max-width: 100%;
            max-height: 100%;
            margin: auto;
        }

    }

    &__text {
        padding: 0 1rem;
    }

    &__title {
        margin-top: 1rem;
        font-weight: bold;
    }

    &__info {
        color: grey;
    }

    &__tags {
        font-size: 1rem;
    }

}