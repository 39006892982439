.singlectablock {
    width: 100%;
    min-height: 500px;
    height: 90vh;
    position: relative;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5rem;

    @include breakpoint(small-only) {
        max-height: 500px;
    }

    &--fullheight {
        height: 90vh;    
    }

    &--halfheight {
        height: 50vh;
    }

    figure {
        width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
        right: 0;
        z-index: -1;
    }

    img {
		object-fit: cover;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
    }
    
    &__content {
        position: relative; 
        text-align: center;
    }

    &__headline {
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: .05em;
        font-size: 2rem;

        @include breakpoint(medium) {
            font-size: 3rem;
        }

        @include breakpoint(large) {
            font-size: 6rem;
        }
    }

    &__text {
        font-weight: 100;
        margin-bottom: 1rem;
    }

    &__link {
        font-weight: bold;

        &, &:visited {
            text-decoration: none;
        }
    }

}