.menu-toggle {
    width: 25px;
    background: transparent;
    height: 25px;
    position: absolute;
    display: block;
    border: 0;
    appearance: none;
    left: 10px;
    top: 10px;
    z-index: 100;
    cursor: pointer;
    
    @include breakpoint(medium) {
      width: 25px;
      color: black !important;
    }
  
    div {
      position: absolute;
      visibility: hidden;
    }
  
    span {
      position: absolute;
      width: 25px;
      height: 3px;
      left: 0px;
      top: calc(50% - 1px);
      transform-style: preserve-3d;
      transition: transform .2s ease 0s, margin-top ease .2s .25s, opacity .2s ease .2s;
  
      @include breakpoint(medium) {
        left: 0px;
      }
  
      .initial & {
        background: white;
  
        @include breakpoint(medium) {
          background: black;
        }
  
      }
  
      &:nth-of-type(1) {
        margin-top: -8px;
      }
  
      &:nth-of-type(2) {
        margin-top: 0;
      }
  
      &:nth-of-type(3) {
        margin-top: 8px;
      }
    }
  
    &.open {
      span {
        transition: transform .2s ease .25s, margin-top ease .2s 0s, opacity .2s ease .2s;
  
        &:nth-of-type(1) {
          transform: rotateZ(45deg);
          margin-top: 0;
        }
  
        &:nth-of-type(2) {
          opacity: 0;
        }
  
        &:nth-of-type(3) {
          transform: rotateZ(-45deg);
          margin-top: 0;
        }
      }
    }
  }

  .navigation {
      display: block;
      position: absolute;
      z-index: 100;
      background: white;
      width: 400px;
      max-width: 100%;
      height: 100vh;
      text-align: left;
      top: 0;
      padding: 4rem 1rem 1rem 4rem;
      transform: translateX(-100%);
      transition: all .5s ease-in-out;
      
      &.shown {
        transform: translateY(0%);

        a {
          transform: translateY(0%);
        }
      }

      a {
        display: block;
        text-decoration: none;
        padding: .3rem 0;

        transform: translateX(-50%);
        transition: transform .6s ease-in-out;
  
      }

      .siteborder & {
        left: -$page_siteborder;
      }
    
  }