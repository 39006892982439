.authorbox {
	
	margin-top: 4rem;
	margin-bottom: 0;
	padding-top: 2rem;
	border-top: 1px solid grey;

	&__inner {
		margin: auto;
		display: flex;
	}	

	&__text {
	}

	&__image {
		max-width: 150px;
		margin-right: 2rem;

		img {
			width: 100%;
		}
	}

	&__name {
		text-transform: uppercase;
		font-weight: 800;
		display: block;
		width: 100%;
		margin-bottom: .8rem;
	}

	&__description {
		margin-bottom: .8rem;
	}

	&__links {
		width: 100%;
		display: block;
	}
}