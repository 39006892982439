.location {	
	font-family: $sans-serif;
	font-weight: 400;
	font-size: 1rem;
	
	a, a:visited {
		text-decoration: none;
	}	
	
	&--small {
  	font-size: .8rem;
	}

}