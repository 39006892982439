$font-path: '/assets/fonts';

/* libre-franklin-100 - latin */
@font-face {
    font-family: 'Libre Franklin';
    font-style: normal;
    font-weight: 100;
    src: url('#{$font-path}/libre-franklin-v2-latin/libre-franklin-v2-latin-100.eot'); /* IE9 Compat Modes */
    src: local('Libre Franklin Thin'), local('LibreFranklin-Thin'),
         url('#{$font-path}/libre-franklin-v2-latin/libre-franklin-v2-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('#{$font-path}/libre-franklin-v2-latin/libre-franklin-v2-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$font-path}/libre-franklin-v2-latin/libre-franklin-v2-latin-100.woff') format('woff'), /* Modern Browsers */
         url('#{$font-path}/libre-franklin-v2-latin/libre-franklin-v2-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
         url('#{$font-path}/libre-franklin-v2-latin/libre-franklin-v2-latin-100.svg#LibreFranklin') format('svg'); /* Legacy iOS */
  }
  /* libre-franklin-300 - latin */
  @font-face {
    font-family: 'Libre Franklin';
    font-style: normal;
    font-weight: 300;
    src: url('#{$font-path}/libre-franklin-v2-latin/libre-franklin-v2-latin-300.eot'); /* IE9 Compat Modes */
    src: local('Libre Franklin Light'), local('LibreFranklin-Light'),
         url('#{$font-path}/libre-franklin-v2-latin/libre-franklin-v2-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('#{$font-path}/libre-franklin-v2-latin/libre-franklin-v2-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$font-path}/libre-franklin-v2-latin/libre-franklin-v2-latin-300.woff') format('woff'), /* Modern Browsers */
         url('#{$font-path}/libre-franklin-v2-latin/libre-franklin-v2-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('#{$font-path}/libre-franklin-v2-latin/libre-franklin-v2-latin-300.svg#LibreFranklin') format('svg'); /* Legacy iOS */
  }
  /* libre-franklin-regular - latin */
  @font-face {
    font-family: 'Libre Franklin';
    font-style: normal;
    font-weight: 400;
    src: url('#{$font-path}/libre-franklin-v2-latin/libre-franklin-v2-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Libre Franklin'), local('LibreFranklin-Regular'),
         url('#{$font-path}/libre-franklin-v2-latin/libre-franklin-v2-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('#{$font-path}/libre-franklin-v2-latin/libre-franklin-v2-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$font-path}/libre-franklin-v2-latin/libre-franklin-v2-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('#{$font-path}/libre-franklin-v2-latin/libre-franklin-v2-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('#{$font-path}/libre-franklin-v2-latin/libre-franklin-v2-latin-regular.svg#LibreFranklin') format('svg'); /* Legacy iOS */
  }
  /* libre-franklin-500 - latin */
  @font-face {
    font-family: 'Libre Franklin';
    font-style: normal;
    font-weight: 500;
    src: url('#{$font-path}/libre-franklin-v2-latin/libre-franklin-v2-latin-500.eot'); /* IE9 Compat Modes */
    src: local('Libre Franklin Medium'), local('LibreFranklin-Medium'),
         url('#{$font-path}/libre-franklin-v2-latin/libre-franklin-v2-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('#{$font-path}/libre-franklin-v2-latin/libre-franklin-v2-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$font-path}/libre-franklin-v2-latin/libre-franklin-v2-latin-500.woff') format('woff'), /* Modern Browsers */
         url('#{$font-path}/libre-franklin-v2-latin/libre-franklin-v2-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('#{$font-path}/libre-franklin-v2-latin/libre-franklin-v2-latin-500.svg#LibreFranklin') format('svg'); /* Legacy iOS */
  }
  /* libre-franklin-600 - latin */
  @font-face {
    font-family: 'Libre Franklin';
    font-style: normal;
    font-weight: 600;
    src: url('#{$font-path}/libre-franklin-v2-latin/libre-franklin-v2-latin-600.eot'); /* IE9 Compat Modes */
    src: local('Libre Franklin SemiBold'), local('LibreFranklin-SemiBold'),
         url('#{$font-path}/libre-franklin-v2-latin/libre-franklin-v2-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('#{$font-path}/libre-franklin-v2-latin/libre-franklin-v2-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$font-path}/libre-franklin-v2-latin/libre-franklin-v2-latin-600.woff') format('woff'), /* Modern Browsers */
         url('#{$font-path}/libre-franklin-v2-latin/libre-franklin-v2-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('#{$font-path}/libre-franklin-v2-latin/libre-franklin-v2-latin-600.svg#LibreFranklin') format('svg'); /* Legacy iOS */
  }
  /* libre-franklin-700 - latin */
  @font-face {
    font-family: 'Libre Franklin';
    font-style: normal;
    font-weight: 700;
    src: url('#{$font-path}/libre-franklin-v2-latin/libre-franklin-v2-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Libre Franklin Bold'), local('LibreFranklin-Bold'),
         url('#{$font-path}/libre-franklin-v2-latin/libre-franklin-v2-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('#{$font-path}/libre-franklin-v2-latin/libre-franklin-v2-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$font-path}/libre-franklin-v2-latin/libre-franklin-v2-latin-700.woff') format('woff'), /* Modern Browsers */
         url('#{$font-path}/libre-franklin-v2-latin/libre-franklin-v2-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('#{$font-path}/libre-franklin-v2-latin/libre-franklin-v2-latin-700.svg#LibreFranklin') format('svg'); /* Legacy iOS */
  }


  /* cormorant-garamond-regular - latin */
@font-face {
    font-family: 'Cormorant Garamond';
    font-style: normal;
    font-weight: 400;
    src: url('#{$font-path}/cormorant-garamond-v5-latin/cormorant-garamond-v5-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Cormorant Garamond Regular'), local('CormorantGaramond-Regular'),
         url('#{$font-path}/cormorant-garamond-v5-latin/cormorant-garamond-v5-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('#{$font-path}/cormorant-garamond-v5-latin/cormorant-garamond-v5-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$font-path}/cormorant-garamond-v5-latin/cormorant-garamond-v5-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('#{$font-path}/cormorant-garamond-v5-latin/cormorant-garamond-v5-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('#{$font-path}/cormorant-garamond-v5-latin/cormorant-garamond-v5-latin-regular.svg#CormorantGaramond') format('svg'); /* Legacy iOS */
  }
  /* cormorant-garamond-italic - latin */
  @font-face {
    font-family: 'Cormorant Garamond';
    font-style: italic;
    font-weight: 400;
    src: url('#{$font-path}/cormorant-garamond-v5-latin/cormorant-garamond-v5-latin-italic.eot'); /* IE9 Compat Modes */
    src: local('Cormorant Garamond Italic'), local('CormorantGaramond-Italic'),
         url('#{$font-path}/cormorant-garamond-v5-latin/cormorant-garamond-v5-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('#{$font-path}/cormorant-garamond-v5-latin/cormorant-garamond-v5-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$font-path}/cormorant-garamond-v5-latin/cormorant-garamond-v5-latin-italic.woff') format('woff'), /* Modern Browsers */
         url('#{$font-path}/cormorant-garamond-v5-latin/cormorant-garamond-v5-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('#{$font-path}/cormorant-garamond-v5-latin/cormorant-garamond-v5-latin-italic.svg#CormorantGaramond') format('svg'); /* Legacy iOS */
  }
  /* cormorant-garamond-500 - latin */
  @font-face {
    font-family: 'Cormorant Garamond';
    font-style: normal;
    font-weight: 500;
    src: url('#{$font-path}/cormorant-garamond-v5-latin/cormorant-garamond-v5-latin-500.eot'); /* IE9 Compat Modes */
    src: local('Cormorant Garamond Medium'), local('CormorantGaramond-Medium'),
         url('#{$font-path}/cormorant-garamond-v5-latin/cormorant-garamond-v5-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('#{$font-path}/cormorant-garamond-v5-latin/cormorant-garamond-v5-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$font-path}/cormorant-garamond-v5-latin/cormorant-garamond-v5-latin-500.woff') format('woff'), /* Modern Browsers */
         url('#{$font-path}/cormorant-garamond-v5-latin/cormorant-garamond-v5-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('#{$font-path}/cormorant-garamond-v5-latin/cormorant-garamond-v5-latin-500.svg#CormorantGaramond') format('svg'); /* Legacy iOS */
  }
  /* cormorant-garamond-500italic - latin */
  @font-face {
    font-family: 'Cormorant Garamond';
    font-style: italic;
    font-weight: 500;
    src: url('#{$font-path}/cormorant-garamond-v5-latin/cormorant-garamond-v5-latin-500italic.eot'); /* IE9 Compat Modes */
    src: local('Cormorant Garamond Medium Italic'), local('CormorantGaramond-MediumItalic'),
         url('#{$font-path}/cormorant-garamond-v5-latin/cormorant-garamond-v5-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('#{$font-path}/cormorant-garamond-v5-latin/cormorant-garamond-v5-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$font-path}/cormorant-garamond-v5-latin/cormorant-garamond-v5-latin-500italic.woff') format('woff'), /* Modern Browsers */
         url('#{$font-path}/cormorant-garamond-v5-latin/cormorant-garamond-v5-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('#{$font-path}/cormorant-garamond-v5-latin/cormorant-garamond-v5-latin-500italic.svg#CormorantGaramond') format('svg'); /* Legacy iOS */
  }
  /* cormorant-garamond-700 - latin */
  @font-face {
    font-family: 'Cormorant Garamond';
    font-style: normal;
    font-weight: 700;
    src: url('#{$font-path}/cormorant-garamond-v5-latin/cormorant-garamond-v5-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Cormorant Garamond Bold'), local('CormorantGaramond-Bold'),
         url('#{$font-path}/cormorant-garamond-v5-latin/cormorant-garamond-v5-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('#{$font-path}/cormorant-garamond-v5-latin/cormorant-garamond-v5-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$font-path}/cormorant-garamond-v5-latin/cormorant-garamond-v5-latin-700.woff') format('woff'), /* Modern Browsers */
         url('#{$font-path}/cormorant-garamond-v5-latin/cormorant-garamond-v5-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('#{$font-path}/cormorant-garamond-v5-latin/cormorant-garamond-v5-latin-700.svg#CormorantGaramond') format('svg'); /* Legacy iOS */
  }
  /* cormorant-garamond-700italic - latin */
  @font-face {
    font-family: 'Cormorant Garamond';
    font-style: italic;
    font-weight: 700;
    src: url('#{$font-path}/cormorant-garamond-v5-latin/cormorant-garamond-v5-latin-700italic.eot'); /* IE9 Compat Modes */
    src: local('Cormorant Garamond Bold Italic'), local('CormorantGaramond-BoldItalic'),
         url('#{$font-path}/cormorant-garamond-v5-latin/cormorant-garamond-v5-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('#{$font-path}/cormorant-garamond-v5-latin/cormorant-garamond-v5-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$font-path}/cormorant-garamond-v5-latin/cormorant-garamond-v5-latin-700italic.woff') format('woff'), /* Modern Browsers */
         url('#{$font-path}/cormorant-garamond-v5-latin/cormorant-garamond-v5-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('#{$font-path}/cormorant-garamond-v5-latin/cormorant-garamond-v5-latin-700italic.svg#CormorantGaramond') format('svg'); /* Legacy iOS */
  }