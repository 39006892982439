.newsletter {
    text-align: center;

    .form-input {
        text-align: center;
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;
    }

    &__description {
        margin-bottom: 1rem;
    }

    a {
        margin-top: .5rem;
        font-size: .8rem;
    }

    
}