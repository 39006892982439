body {	
	font-family: $sans-serif;
	font-size: 1rem;
	line-height: 1.8;

	@include breakpoint(medium) {
		font-size: 1.2rem;
	}

}

p {
	font-weight: 400;
	font-family: $sans-serif;
}

strong {
	font-weight: bold;
}

.main-headline {
	font-size: 3rem;
	line-height: 3rem;
	margin-bottom: 1rem;
	font-family: $serif;
	font-style: italic;
	font-weight: bold;

	@include breakpoint(medium) {
		font-size: 5.5rem;
		line-height: 5rem;
		font-weight: normal;
	}

	&--page {
		font-style: normal;
		font-weight: bold;
		text-transform: uppercase;
	}
}

.intro {
	font-family: $sans-serif;
	font-weight: 300;
	font-size: 1rem;
	line-height: 1.7;

	@include breakpoint(medium) {
		font-size: 1.3rem;
	}

	&--page {
		color: #616161;
	}
}


.h2,
.h2 p {
	font-weight: 700;
	font-family: $serif;
	font-size: 3rem;
	line-height: 1.1;
	margin-bottom: .5rem;
}

.bodyText {
	margin-bottom: 2rem;
	
	p {
		margin-bottom: 1em;
	}
	
	&.variant1 p {
		font-weight: bold;
		font-family: $serif;
		font-size: 2.1rem;
		line-height: 1.4;
	}

	&.variant2 p {
	  font-family: $sans-serif;
	  font-style: normal;
	  font-weight: 300;
	}

	&.variant3 p {
	  font-family: $serif;
	  font-style: italic;
	  font-size: 1.9rem;
	  line-height: 1.4;
	  font-weight: 600;
	}

	&.variant4 p {
		font-weight: bold;
		font-family: $serif;
		font-size: 2.1rem;
		line-height: 1.4;
		padding: .6rem;
	}
}

.fas {
	margin-right: .3em;
	width: 1em;
}

.left {
	text-align: left;
}

.center {
	text-align: center;
}

a {
	color: black;

	&:visited {
		color: black;
	}
}