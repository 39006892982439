.quote {
	margin-top: 3rem;
	margin-bottom: 2rem;
	
	blockquote {
		font-family: $serif;
		font-style: italic;
		margin-bottom: 1rem;
		font-size: 2rem;
		line-height: 1.4;
		position: relative;

		@include breakpoint(medium) {
			font-size: 2.8rem;
		}
	
	}
	
	.citation {
	  font-family: $sans-serif;
	  font-style: normal;
	  font-weight: 400;
    }
    
    &.overhang {
        text-align: center;
    }

    &.type-area {
        text-align: left;
        padding-left: 1rem;
        border-left: 5px solid black;
    }
}
