.button {
    color: black;
    text-decoration: none;
    font-weight: bold;
    border: 1px solid #616161;
    display: inline-block;
    width: auto !important;
    padding: .2rem .5rem;
    border-radius: 3px;

    &:hover {
        background: #f1f1f1;
    }
}