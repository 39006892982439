.articleteaser {
  display: block;
  position: relative;
  border: 0 solid lightgrey;
  background: white;
  min-height: 14rem;
  //transition: all 1s ease;
  padding-bottom: 20px;
  
  &:after {
    content: '';
    height: 1px;
//     width: 100%;
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 20px;
    background: lightgrey;
  }
  
  &:hover {
    z-index: 10;
  }

  &__text {
    padding: 0 20px 20px 20px;
  }
  
  &__headline {
    margin-top: 1rem;
    margin-bottom: .5rem;
    font-family: $serif;
    font-weight: bold;
    font-size: 2.6rem;
    line-height: 1;
  }
  
  &__intro {
  	font-family: $sans-serif;
  	font-weight: 300;
  	font-size: 1rem;
  	line-height: 1.7;
    color: grey;
    margin-bottom: .5rem;
  }
    

  //IMAGE 
  &--image &__image {
    position: relative;
    display: block;
    padding-top: 56%;
    overflow: hidden;
  

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      transition: transform 10s ease;
    }
  
  }

  &:hover &__image img {
    transform: scale(1.1);
}

  &--image &__intro {
  	max-width: 40rem; 
  }


  // NO IMAGE

  &--no-image {
    background: #f1f1f1;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    
  }


  &--no-image &__headline {
    font-style: italic;
  }
}

