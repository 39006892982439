.content {
	&__body {
		margin-top: 5rem;
		
		> section,
		> div {
			margin-left: auto;
			margin-right: auto;
			position: relative;		
			clear: both;
		}
	}	
}

.type-area,
.overhang {
	width: calc(100% - 40px);

	@include breakpoint(medium) {
		width: 100%;
	}

	&.image {
		width: 100%;
	}
}

.type-area {
	max-width: $page-width_type-area;
}

.overhang {
	max-width: $page-width_overhang;
}

.fullwidth {
	width: $page-width_fullwidth;
}