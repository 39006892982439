.accentcolor--red {
	a, a:visited {
		color: red;
	}	
	
	.variant4 p {
		background: #cc0000;
		color: white;
	}

	.quote.type-area {
		border-color: red;
	}
}

.accentcolor--blue {
	a, a:visited {
		color: blue;
	}	
	
	.variant4 p {
		background: #3d2dea;
		color: white;
	}

	.quote.type-area {
		border-color: #3d2dea;
	}

}