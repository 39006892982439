$td-background-light: #f6f6f6; 
$td-background-dark: #151517; 

$td-grey-dark: #252525; 
$td-grey-medium: #616161; 
$td-grey-light: #a7a7a7; 
$td-white: white; 

.theme--dark {
	background: $td-background-dark;
	color: $td-white;
	
	&.cover:not(.cover--fullscreen) {
		&:after {
			background: $td-background-dark;
		}
	}

	.brand a {
		color: white !important;
	}

	.menu-toggle span {
		background: white;
	}

	.navigation {
		background: black;

		a {
			color: white !important;
		}
	}
	
	a, a:visited {
		color: white;
	}

	.main-headline, 
	.intro {
		//color: black;
	}
	
	.intro {
		color: $td-grey-light;
	}
	
	.location {
		
		a, a:visited, & {
		}
	}

	.cover__text {

		@include breakpoint(small-only) {
			background: $td-background-dark;
		}

	}
	

}