.filter {
    max-width: 40rem;
    margin: auto;

    &__item {
        text-decoration: none;
        color: #252525;
        font-size: .9rem;
        margin: .2rem;

        &--active {
            color: #616161;
        }
    }

    &__description {
        font-size: .9rem;
        font-weight: bold;
    }
}