// Breakpoint
// Usage: @include breakpoint(medium) {content}

@mixin breakpoint($media) {
    @if $media == small {
      @media only screen { @content; }
    }
    @if $media == small-only {
      @media only screen and (max-width: $screen-s-max) { @content; }
    }
    @else if $media == medium {
      @media only screen and  (min-width: $screen-s-max+1)  { @content; }
    }
    @else if $media == medium-only {
      @media only screen and (min-width: $screen-s-max+1) and (max-width: $screen-m-max)  { @content; }
    }
    @else if $media == large {
      @media only screen and (min-width: $screen-m-max+1) { @content; }
    }
    @else if $media == large-only {
      @media only screen and (min-width: $screen-m-max+1) and (max-width: $screen-l-max) { @content; }
    }
    @else if $media == xlarge {
      @media only screen and (min-width: $screen-l-max+1) { @content; }
    }
  }
  //------------
  