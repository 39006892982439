.pagination {
    text-align: center;
}

.pagination-item {
    display: inline-block;
    width: 3rem;
}

.is-inactive {
    visibility: hidden;
}