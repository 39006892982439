$tl-background-light: white; 

$tl-grey-dark: #252525; 
$tl-grey-medium: #616161; 

.brand a {
	color: black;
}

.menu-toggle span {
	background: black;
}

.navigation {
	background: white;
	border-right: 1px solid $tl-grey-medium;
	box-shadow: 2px 2px 10px rgba($tl-grey-medium, 0);

	&.shown {
		box-shadow: 2px 2px 10px rgba($tl-grey-medium, .4);
	}
	a, a:visited {
		color: black !important;
	}
}

.theme--light {
	background: $tl-background-light;
	color: $tl-grey-dark;
	
	&.cover:not(.cover--fullscreen) {
		&:after {
			background: $tl-background-light;
		}
	}

	.fas {
		color: $tl-grey-medium;
	}
	
	.brand a {
		color: black !important;
	}

	.menu-toggle span {
		background: black;
	}

	.navigation {
		background: white;

		a, a:visited {
			color: black !important;
		}
	}



	a, a:visited {
		color: black;
	}
	
	.intro {
		color: $tl-grey-medium;
	}
	
	.location {
		
		a, a:visited, & {
		}
	}

	.cover__text {

		@include breakpoint(small-only) {
			background: $tl-background-light;
		}
	}

	.authorbox {

	}

}