.meta {
	
	&__date,
	&__author {
		display: inline-block;
		font-family: $sans-serif;
		font-weight: 400;
		font-size: 1.1rem;
	}
	
	&__date {
	  display:none;
	}
		
}