.location-teaser {
    height: 19rem;
    position: relative;
    overflow: hidden;


    img {
        width: 100%;
    }

    h2 {
        position: absolute;
        z-index: 2;
        color: white;
        font-size: 2rem;
        font-weight: 500;
        text-align: center;
        width: 100%;
        bottom: 2rem;
        text-shadow: 2px 0 20px rgba(black, .8);
        text-transform: uppercase;
        letter-spacing: .05em;
        transition: text-shadow 1s ease;
    }

    &__image {
        width: 100%;
        height: 100%;
        position: absolute;

        &:after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
			background: linear-gradient(to bottom, rgba(0,0,0,0) 60%, rgba(0,0,0,.7) 100%);
            opacity: 1;  
            transition: all 1s ease;
        }

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          transition: transform 10s ease;
        }
      
      }

    &:hover &__image img {
      transform: scale(1.1);
    }

    &:hover &__image:after {
        opacity: 0;
    }

    &:hover h2 {
        //text-shadow: 2px 0 20px rgba(black, 1);
    }


}