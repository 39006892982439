@import 'misc/reset';
@import 'misc/variables';
@import 'misc/breakpoint';

@import 'layout/typography',
        'layout/layout',
        'layout/menu',
        'layout/content';

@import 'modules/button',
        'modules/header',
        'modules/brand',
        'modules/title-image',
        'modules/pagination',
        'modules/cover',
        'modules/image',
        'modules/meta',
        'modules/tags',
        'modules/location',
        'modules/authorbox',
        'modules/singlearticle',
        'modules/articlelist',
        'modules/articleteaser',
        'modules/ctasection',
        'modules/ctabox',
        'modules/quote',
        'modules/landingpage',
        'modules/singlectablock',
        'modules/location-teaser',
        'modules/shop',
        'modules/filter',
        'modules/form',
        'modules/newsletter',
        'modules/footer';

@import 'colors/theme--light';
@import 'colors/theme--dark';

@import 'colors/accentcolors';

@import 'misc/fonts';

@import 'vendor/featherlight.css', 
        'vendor/featherlight.gallery.css',
        'vendor/fontawesome/fontawesome.scss',
        'vendor/fontawesome/solid.scss';

