body {
	margin: 0;
	
	&.siteborder {
		margin: $page_siteborder;
	}
}

.wrapper {
  	max-width: 2000px;
	margin: 0 auto;

	> *:last-child {
		margin-bottom: 4rem;
	}
}

.main {
	margin-top: 6rem;
}

.article .main {
	margin-top: 0;
}

/*
.home__body {
	> section {
		@include breakpoint(medium) {
			padding: 0 1rem;
		}
	
		@include breakpoint(large) {
			padding: 0 3rem;
		}
	
	}
}
*/

.grid {
	margin-bottom: 5rem;

	&__inner {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
	}
}

.grid--x3 .grid-cell {
	width: 100%;
	margin-bottom: 0;

	@include breakpoint(medium) {
		width: 33.333%;

		&:nth-child(3n+1),  
		&:nth-child(3n+3) {
			margin-left: 0;
			margin-right: 0;
		}
	}
}

.grid--subpages {
	//background: black;
	//border-top: 7px solid black;
	//border-bottom: 7px solid black;
}

.grid--subpages .grid-cell {
	width: 100%;

	@include breakpoint(medium) {
		width: 25%;	
		
		border: 0 solid white;
		
		&:nth-child(4n) {
			border-right-width: 0;
		}
	
		&:nth-child(4n+1) {
			border-left-width: 0;
		}
	
	}


}

.page-header {
	text-align: center;
	margin-bottom: 2rem;

	&__text {
		margin-bottom: 1rem;
		padding: 20px;
	}

}


.map {
	height: 30rem;
	margin-bottom: 5rem;
	margin-top: 5rem;
	transition: all 1s ease;
	position: relative;

	&__headline {
		position: absolute;
		top: 2rem;
		width: 100%;
		padding: 0 1rem;
		font-size: 3rem;
		font-weight: bold;
		transition: all 1s ease;
		z-index: 10;
		pointer-events: none;
		text-align: center;
	}

	&__description {
		position: absolute;
		bottom: 2rem;
		width: 100%;
		padding: 0 1rem;
		font-size: 2rem;
		font-weight: 300;
		transition: all 1s ease;
		z-index: 10;
		pointer-events: none;
		text-align: center;
	}

	&:hover &__headline{
		opacity: .4;
		transform: translateY(-1rem) scale(.7);
	}

	&:hover &__description{
		opacity: .4;
		transform: translateY(1rem) scale(.7);
	}
}

.maps {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transition: all 1s ease;
	transform-origin: top center;
}
