$page-width_type-area: 720px;
$page-width_overhang: 1100px;
$page-width_fullwidth: 100%;

$page_siteborder: 20px;

$global_gutter: 40px;

$serif: 'Cormorant Garamond', serif;
$sans-serif: 'Libre Franklin', sans-serif;

// Screensizes
$screen-s-max: 999px;
$screen-m-max: 1400px;
$screen-l-max: 2000px;
