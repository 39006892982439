.ctabox {
    background: #222;
    padding: 3rem 20px 3.5rem;
    color: #fff !important;
    text-decoration: none;
    text-align: center;

    &__headline {
        margin-top: 0rem;
        font-family: $sans-serif;
        font-weight: 300;
        font-size: 2.3rem;
      }
      
      &__text,
      &__label {
          font-family: $sans-serif;
          font-weight: 100;
          font-size: 1rem;
          line-height: 1.7;
      }

      &__label {
          font-weight: 500;
          margin-top: 1rem;
      }
    
}