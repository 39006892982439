.articlelist {

  &--dark &__inner {
    background: #525252;
  }

  &--white &__inner {
    background: white;
  }

  &--light &__inner {
    background: #cfcfcf;
  }

  &--padding &__inner {
    padding: 6rem;
    margin: 0 auto;
  }
  
  &--image &__inner {
    background-origin: center;
    background-size: cover;
  }
  
  a, a:visited {
    text-decoration: none;
    color: black;
  }
  
  &__headline {
    display: block;
    width: 100%; 
    text-align: center;
    padding: 20px;
    margin-top: 5rem;
    margin-bottom: .5rem;
    font-size: 1.2rem;
    font-weight: 300;
    text-transform: uppercase;
    color: grey;
    letter-spacing: .1em;
  }
  
}