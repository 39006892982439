.title-image {
	position: relative;
	height: 40vh;
    min-height: 300px;
    max-height: 500px;
	
	.siteborder & {
		height: calc(100vh - 40px);
	
		&:after {
			content: '';
			position: absolute;
			left: -$page_siteborder;
			right: -$page_siteborder;
			top: -$page_siteborder;
			bottom: -$page_siteborder;
			z-index: -1;
		}
    }
    
    picture {
        position: absolute;
		width: 100%;
		margin: 0;
		top: 0;
		bottom: 0;
		left: 0;
        right: 0;
        
        z-index: 1;
		height: 100%;

		img {
			object-fit: cover;	
			width: 100%;
			height: 100%;
		}

	}

}