.tags {
	font-family: $sans-serif;
	font-weight: 400;
	font-size: 1rem;
	  
  a, a:visited {
    color: black;
  }
  
  &--small {
  	
  	font-family: $sans-serif;
  	font-weight: 400;
    font-size: .8rem;
    
    span, 
    a {
    }
  }
}