.image,
.imagecluster {
	margin-bottom: 5rem;
	margin-top: 5rem;
}

.image, 
.imagetext {
	figcaption {
		margin-top: 0rem;
		color: #8e8e8e;
		font-size: .85rem;
		font-family: $sans-serif;
		text-align: center;
	}
	
	img {
		width: 100%;
	}
}

.image + .image, 
.image + .imagecluster, 
.imagecluster + .image, 
.imagecluster + .imagecluster {
	margin-top: -5rem;
}

.image.fullwidth + .image.overhang,
.image.fullwidth + .image.type-area,
.imagecluster.fullwidth + .image.overhang,
.imagecluster.fullwidth + .image.type-area {
	margin-top: 0;
}

.image--has-caption {
	padding-bottom: 5rem;
}


.imagetext {
	
	&--image-left &__image,
	&--image-right &__image {

		@include breakpoint(medium) {
			width: ($page-width_type-area/2) - $global_gutter + ($page-width_overhang - $page-width_type-area)/2;
			width: 300px;
			max-width: 100%;
		}

		@include breakpoint(large) {
			width: ($page-width_type-area/2) - $global_gutter + ($page-width_overhang - $page-width_type-area)/2;
			max-width: 100%;
		}

	}
	
	&--image-left &__image {

		@include breakpoint(medium) {
			float: left;
			margin-right: $global_gutter;
			margin-left: -100px;	
		}

		@include breakpoint(large) {
			margin-left: -($page-width_overhang - $page-width_type-area)/2;	
		}
	}

	&--image-right &__image {

		@include breakpoint(medium) {
			float: right;
			margin-left: $global_gutter;
			margin-right: -100px;	
		}

		@include breakpoint(large) {
			margin-right: -($page-width_overhang - $page-width_type-area)/2;	
		}
	}
	
	figcaption {
		text-align: left;
		margin-bottom: 1rem;

		@include breakpoint(medium) {
			margin-bottom: 0;
		}
	}
}

.imagecluster {

	img {
		object-fit: cover;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}

	&--1x3 {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		
		figure {
			width: 100%;
			margin-bottom: 10px;
			height: 0;
			position: relative;
			
			@include breakpoint(medium) {
				width: calc(33.3% - 5px);
			}
	
			
		}
	}

	&--1x2 {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		
		figure {
			width: 100%;
			margin-bottom: 10px;
			height: 0;
			position: relative;
			
			@include breakpoint(medium) {
				width: calc(50% - 5px);
			}
				
		}
	}

	&--2x2 {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		
		figure {
			width: 100%;
			margin-bottom: 10px;
			height: 0;
			position: relative;
			
			@include breakpoint(medium) {
				width: calc(50% - 5px);
			}

		}
	}
	
	&--square {
		figure {
			padding-top: 100%;

			@include breakpoint(medium) {
				padding-top: 50%;
			}

		}
	}

	&--landscape {
		figure {
			padding-top: 56%;

			@include breakpoint(medium) {
				padding-top: 40%;
			}

		}
	}

	&--portrait {
		figure {
			padding-top: 120%;

			@include breakpoint(medium) {
				padding-top: 60%;
			}

		}
	}

}

.imagecluster--1x3.imagecluster--square {

	figure {
		padding-top: 100%;

		@include breakpoint(medium) {
			padding-top: calc(33.3% - 5px);
		}

	}
}

.imagecluster--1x3.imagecluster--landscape {

	figure {
		padding-top: 60%;

		@include breakpoint(medium) {
			padding-top: 25%;
		}

	}
}

.imagecluster--1x3.imagecluster--portrait {	

	figure {
		padding-top: 120%;

		@include breakpoint(medium) {
			padding-top: 38%;
		}

	}
}
