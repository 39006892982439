.brand {
    position: absolute;
    left: 3rem;
    top: 8px;
    z-index: 101;

    a, a:visited {
        text-decoration: none;
        font-weight: bold;        
    }
}